<!-- Version: 1.0 -->
<template>
  <div>
    <span class="eh-base-input-label-without-float">
      {{ label }}
      <span v-if="required && label" style="color: red">*</span>
    </span>
    <input
      :disabled="disabled"
      type="file"
      @input="$emit('change', $event)"
      :multiple="multiple"
      class="eh-base-input without-float"
      :class="setClass"
    />
    <div class="eh-base-input-desc-error-box">
      <div v-if="description" class="eh-base-input-description">
        {{ description }}
      </div>
      <div v-if="!state && error" class="eh-base-input-error-text">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
//import moment from "moment";
export default {
  name: "fp-input",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    label: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String, //TODO: Nincs kiválasztva szöveg helyett?
    description: String,
    error: String,
    value: [String, Number, FileList],
    state: { type: Boolean, default: true },
    multiple: Boolean,
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.label) {
        temp += " with-label ";
      } else {
        temp += " without-label ";
      }
      if (this.state) {
        temp += " valid-input ";
      } else {
        temp += " invalid-input ";
      }
      return temp;
    },
  },
};
</script>